<script>
import CryptoJS from 'crypto-js';

export default {
  name: 'GenerateSignMixin',
  methods: {
    generateSign(content) {
      const key = CryptoJS.enc.Utf8.parse(
        this.$moment().format('YYYY-MM-DD HH:mm'),
      );
      const sign = CryptoJS.AES.encrypt(content, key, {
        keySize: 16,
        iv: key,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }).toString();
      return sign;
    },
  },
};
</script>
