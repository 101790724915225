import Vue from 'vue';
import moment from 'moment-timezone';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

moment.tz.setDefault('Asia/Bangkok');
Vue.prototype.$moment = moment;

Vue.config.productionTip = false;

Vue.filter("trackingStatusDateDisplay", function (value, format = 'YYYY-MM-DD HH:mm') {
	// origin '18/12/2563 12:09:08+07:00'
	try {
		return moment(value, "DD/MM/YYYY hh:mm:ssZZ").subtract(543, 'years').format(format)
	}catch(err) {
		return ''
	}
});

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');
